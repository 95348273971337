export class TraceabilityFiltersRequestDTO {
    dateFrom: string;
    dateTo: string;
    deliverNote: string | null;
    username: string | null;
    providersIds: number[] | null;
    ref: string | null;
    productName: string | null;
    batch: string | null;

    centerGroupsIds: number[] | null;
    centerIds: number[] | null;


    timeFromDate: Date;
    timeFrom?: string;
    timeToDate: Date;
    timeTo?: string;
    clientId: number;
    searchUp: boolean;
}
