import { Injectable } from '@angular/core';
import { CenterGroupDTO } from 'src/app/DTO/CenterGroupDTO';
import { ApiService } from 'src/app/services/api.service';
import { API_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CenterGroupsService {

  constructor(
    private api: ApiService,
  ) { }

  getGroups(clientId: number): Promise<CenterGroupDTO[]> {
    return this.api.get(`${API_URL}/centers/groups?clientId=${clientId}`);
  }

  getById(clientId: number, id: number): Promise<CenterGroupDTO> {
    return this.api.get(`${API_URL}/centers/groups/${id}?clientId=${clientId}`);
  }

  put(clientId: number, center: CenterGroupDTO) {
    return this.api.put(`${API_URL}/centers/groups?clientId=${clientId}`, center);
  }

  post(clientId: number, center: CenterGroupDTO) {
    return this.api.post(`${API_URL}/centers/groups?clientId=${clientId}`, center);
  }


}
