<div class="px-5 py-4">

    <h1 class="mb-4"> <mat-icon>line_axis</mat-icon>
        {{'TRAZABILITY.Trazabilidad' | translate}}</h1>

    <form  #filtersForm="ngForm" [formGroup]="formGroup" id="formFilters">
        <div class="filters-block mat-elevation-z2">
            <span class="p-float-label" >

                <p-calendar
                        [(ngModel)]="filters.dateFrom"
                        [readonlyInput]="true"
                        formControlName="dateFrom"
                        dateFormat="dd/mm/yy"
                        inputId="dateFrom"
                        (ngModelChange)="controlDates()"
                ></p-calendar>
                  <label for="dateFrom" [ngClass]="(formGroup.get('dateFrom')?.hasError('required') && filtersForm?.submitted) ? 'text-danger' : ''">{{'TRAZABILITY.Fecha desde' | translate}} *</label>

           </span>

            <span class="p-float-label">
                <p-calendar
                        [(ngModel)]="filters.dateTo"
                        [readonlyInput]="true"
                        formControlName="dateTo"
                        dateFormat="dd/mm/yy"
                        inputId="dateTo"
                        (ngModelChange)="controlDates()"
                ></p-calendar>
                <label for="dateTo" [ngClass]="(formGroup.get('dateTo')?.hasError('required') && filtersForm?.submitted) ? 'text-danger' : ''">{{'TRAZABILITY.Fecha hasta' | translate}} *</label>

           </span>

            <span class="p-float-label">
                <input pInputText id="Albaran" [ngModelOptions]="{standalone: true}" [(ngModel)]="filters.deliverNote" />
                <label htmlFor="Albaran">{{'TRAZABILITY.DeliveryNote' |translate}}</label>
            </span>
            <span class="p-float-label">
                <input pInputText id="producer" [ngModelOptions]="{standalone: true}" [(ngModel)]="filters.username" />
                <label htmlFor="producer">{{'TRAZABILITY.UserName' |translate}}</label>
            </span>

            <br>

            <span class="p-float-label">
                <input pInputText id="sku" [ngModelOptions]="{standalone: true}" [(ngModel)]="filters.ref" />
                <label htmlFor="sku">SKU / SAN <span *ngIf="filters.searchUp">/ Nº RECETA</span></label>
            </span>
            <span class="p-float-label">
                <p-multiSelect
                        [(ngModel)]="filters.providersIds"
                        [ngModelOptions]="{standalone: true}"
                        name="providers"
                        [options]="providers"
                        optionLabel="name"
                        optionValue="id"
                        scrollHeight="500px"
                        filterBy="code,name"
                >
                    <ng-template let-item pTemplate="item">
                        <div>{{ item.code }} - {{ item.name }}</div>
                    </ng-template>
                    <ng-template let-item pTemplate="selectedItem">
                        <div>{{ item.code }} - {{ item.name }}</div>
                    </ng-template>
                </p-multiSelect>
                <label for="providers">{{'TRAZABILITY.Suplier' |translate}}</label>
            </span>
            <span class="p-float-label">
                <input pInputText id="nameProduct" [ngModelOptions]="{standalone: true}" [(ngModel)]="filters.productName" />
                <label htmlFor="nameProduct">{{'TRAZABILITY.ProductName' |translate}}</label>
            </span>
            <span class="p-float-label">
                <input pInputText id="lote" [ngModelOptions]="{standalone: true}" [(ngModel)]="filters.batch" />
                <label htmlFor="lote">{{'TRAZABILITY.Batch' |translate}}</label>
            </span>
            <br>
            <span class="p-float-label">
                    <p-multiSelect
                            [(ngModel)]="filters.centerGroupsIds"
                            [ngModelOptions]="{standalone: true}"
                            name="centerGroups"
                            [options]="centerGroups"
                            optionLabel="name"
                            optionValue="id"
                            scrollHeight="500px"
                            (ngModelChange)="onCenterGroupsChange()"
                    >
                    </p-multiSelect>
                    <label for="centerGroups">{{'CENTER_GROUPS.Title' | translate}}</label>
                </span>
            <span class="p-float-label">
                    <p-multiSelect
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="filters.centerIds"
                            name="centers"
                            [options]="centers"
                            optionLabel="name"
                            optionValue="id"
                            scrollHeight="500px"
                    >
                        <ng-template let-item pTemplate="item">
                            <div>{{ item.code }} - {{ item.name }}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <div>{{ item.code }} - {{ item.name }}</div>
                        </ng-template>
                    </p-multiSelect>
                    <label for="centers">{{'TRAZABILITY.Unidades' | translate}}</label>
                </span>

            <span class="p-float-label">

                <p-calendar
                        [(ngModel)]="filters.timeFromDate"
                        [ngModelOptions]="{standalone: true}"
                        [readonlyInput]="true"
                        [timeOnly]="true"
                        inputId="timeFrom"
                ></p-calendar>
                    <label for="timeFrom">{{'TRAZABILITY.DesdeHora' | translate}}</label>
           </span>
            <span class="p-float-label" >
                <p-calendar
                        [(ngModel)]="filters.timeToDate"
                        [ngModelOptions]="{standalone: true}"
                        [timeOnly]="true"
                        [readonlyInput]="true"
                        inputId="timeTo"
                ></p-calendar>
             <label for="timeTo">{{'TRAZABILITY.FromHora' | translate}}</label>
           </span>
            <div>
            <div style="margin-top: 28px;">
                <p-selectButton
                        [ngModelOptions]="{standalone: true}"
                        [options]="filterBoolOptions"
                        [(ngModel)]="filters.searchUp"
                        optionLabel="name"
                        optionValue="value"
                        aria-labelledby="basic">
                </p-selectButton>
            </div>


            </div>
            <br>
            <button mat-flat-button color="primary" class="search" type="submit" [disabled]="disabledButton" (click)="sendFilters()"><mat-icon>search</mat-icon> {{ 'Search' | translate }}</button>
            <span class="text-danger ms-3" *ngIf="disabledButton">El rango de fechas es mayor a 6 meses.</span>
        </div>
    </form>
    <div *ngIf="isLoading">
        <p-skeleton width="100%" height="60px" styleClass="mb-4" />
        <p-skeleton width="100%" height="60px" styleClass="mb-4" />
        <p-skeleton width="100%" height="60px" styleClass="mb-4" />
    </div>

    <p-accordion [multiple]="true" [activeIndex]="[0,1,2,3,4]" *ngIf="this.tableData && !isLoading">
        <ng-container *ngIf="isSearchUp; else normalOrder">
            <p-accordionTab header="PRODUCTO FINAL">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportLabelsFinalsDTOs" [dateSecondary]="true" [type]="2">
                </app-traceability-table-report>
            </p-accordionTab>
            <p-accordionTab header="PRODUCTO INTERMEDIO">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportLabelsIntermediateDTOs" [dateSecondary]="true" [type]="2">
                </app-traceability-table-report>
            </p-accordionTab>
            <p-accordionTab header="MANIPULACIÓN MATERIA PRIMA">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportLabelsRawDTOs" [dateSecondary]="true" [type]="2">
                </app-traceability-table-report>
            </p-accordionTab>
            <p-accordionTab header="TRANSFERENCIAS">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportTransfersDTOs" [type]="1">
                </app-traceability-table-report>
            </p-accordionTab>
            <p-accordionTab header="RECEPCIÓN DE MERCANCIA">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportRecepcionsDTOs" [type]="0">
                </app-traceability-table-report>
            </p-accordionTab>
        </ng-container>

        <ng-template #normalOrder>
            <p-accordionTab header="RECEPCIÓN DE MERCANCIA">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportRecepcionsDTOs" [type]="0">
                </app-traceability-table-report>
            </p-accordionTab>
            <p-accordionTab header="TRANSFERENCIAS">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportTransfersDTOs" [type]="1">
                </app-traceability-table-report>
            </p-accordionTab>
            <p-accordionTab header="MANIPULACIÓN MATERIA PRIMA">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportLabelsRawDTOs" [dateSecondary]="true" [type]="2">
                </app-traceability-table-report>
            </p-accordionTab>
            <p-accordionTab header="PRODUCTO INTERMEDIO">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportLabelsIntermediateDTOs" [dateSecondary]="true" [type]="2">
                </app-traceability-table-report>
            </p-accordionTab>
            <p-accordionTab header="PRODUCTO FINAL">
                <app-traceability-table-report [tableData]="this.tableData.traceabilityReportLabelsFinalsDTOs" [dateSecondary]="true" [type]="2">
                </app-traceability-table-report>
            </p-accordionTab>
        </ng-template>
    </p-accordion>

</div>
