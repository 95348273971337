import {Component, OnInit, ViewChild} from '@angular/core';
import {TraceabilityFiltersRequestDTO} from "../../../DTO/Requests/TraceabilityFiltersRequestDTO";
import {ApiService} from "../../../services/api.service";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {ClientDTO} from "../../../DTO/ClientDTO";
import {Subscription} from "rxjs";
import {StorageService} from "../../../services/storage.service";
import {TraceabilityReportDTO} from "../../../DTO/Requests/TraceabilityReportDTOS";
import {DateTime} from "luxon";
import { CenterGroupsService } from '../../center-groups/center-groups.service';
import { X } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-traceability',
  templateUrl: './traceability.component.html',
  styleUrls: ['./traceability.component.scss']
})

export class TraceabilityComponent implements OnInit {

  public filters: TraceabilityFiltersRequestDTO;
  public tableData: TraceabilityReportDTO;

  public isLoading = false;
  public isSearchUp = false;
  public disabledButton = false;

  @ViewChild('filtersForm') formFilters: NgForm;

  public filterBoolOptions = [
    {
      name: 'Hacia adelante',
      value: false
    },
    {
      name: 'Hacia atras',
      value: true
    }];

  public centerGroups: any[];
  public providers: any[];
  public allCenters: any[];
  public centers: any[];
  public formGroup: FormGroup;
  public client: ClientDTO;
  private sub: Subscription;

  constructor(
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private storage: StorageService,
    private centerGroupsService: CenterGroupsService
  ) {}

  ngOnInit(): void {

    this.formGroup = this._formBuilder.group({
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required]
    });

    this.sub = this.storage.client.subscribe((res) => {
      if(res){
        this.client = res;

        this.centerGroupsService.getGroups(res.id)
        .then((res) => {
          this.centerGroups = res;
          this.filters.centerGroupsIds = [];
        });

        this._apiService.getCenters(res.id).then((units) => {
          this.allCenters = units;
          this.centers = units;
          this.filters.centerIds = [];
        });

        this._apiService.getSuppliers(this.client.id).then((supliers) => {
          this.providers = supliers;
          this.filters.providersIds = [];
        });
      }
    });

    this.filters = new TraceabilityFiltersRequestDTO();
    this.filters.searchUp = false;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  
  controlDates(){
    if (this.filters.dateTo && this.filters.dateFrom) {
      const dateFrom = new Date(this.filters.dateFrom);
      const dateTo = new Date(this.filters.dateTo);

      if (!isNaN(dateFrom.getTime()) && !isNaN(dateTo.getTime())) {
        const monthsDiff = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 + (dateTo.getMonth() - dateFrom.getMonth());

        if (monthsDiff > 6) {
          this.disabledButton = true;
        } else {
          this.disabledButton = false;
        }
      }
    }

  }

  onCenterGroupsChange(){
    if (this.filters.centerGroupsIds?.length){
      this.centers = this.allCenters.filter(x => this.filters.centerGroupsIds?.includes(x.groupId));
      this.filters.centerIds = this.filters.centerIds?.filter(x => this.centers.map(y => y.id).includes(x)) ?? null;
    } else {
      this.centers = this.allCenters;
    }
  }

  sendFilters(){

    if(this.formGroup.valid){
      this.isLoading = true;
      if(this.client){
        if(this.filters.timeToDate && this.filters.timeToDate){
          this.filters.timeTo = DateTime.fromJSDate(this.filters.timeToDate).toISO();
          this.filters.timeFrom = DateTime.fromJSDate(this.filters.timeFromDate).toISO()
        }
        this.isSearchUp = this.filters.searchUp;
        this.filters.clientId = this.client.id;
        this._apiService.filtersTraceabilitySend(this.filters).then(res => {
          this.tableData = res;
          console.log(res);
          this.isLoading = false;
        })
      }

    }
  }

}
